<template>
  <div class="card">
    <div class="header">
      <div class="title">创建任务</div>
    </div>
    <div class="form">
      <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :colon="false">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="任务名称">
              <a-input v-decorator="[
        'name',
        {
          rules: [{ required: true, message: '请输入' }],
        },
      ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="任务类型">
              <a-select v-decorator="[
        'type',
        {
          rules: [
            {
              required: true,
              message: '请选择',
            },
          ],
        },
      ]">
                <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name
                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="任务日期">
              <a-date-picker style="width: 100%" placeholder v-decorator="[
        'taskTime',
        {
          rules: [{ required: true, message: '请选择' }],
        },
      ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="执行人">
              <a-select v-decorator="[
        'executor',
        {
          rules: [
            {
              required: true,
              message: '请选择',
            },
          ],
        },
      ]">
                <a-select-option v-for="item in userList" :key="item.id" :value="item.id">{{ item.name
                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="任务说明" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <a-select mode="multiple" style="width: 100%" v-decorator="[
        'remark',
        {
          rules: [
            {
              required: true,
              message: '请选择',
            },
          ],
        },
      ]">
                <a-select-option v-for="item in remarks" :key="item.remark" :value="item.remark">{{ item.remark
                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="任务状态" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <a-radio-group v-decorator="[
        'status',
        {
          rules: [
            {
              required: true,
              message: '请选择',
            },
          ],
        },
      ]">
                <a-radio v-for="item in statusList" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="设备" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <a-button type="primary" size="small" @click="changeStatus('facility')">添加</a-button>

              <a-table bordered size="small" :data-source="selectedFacilityList" :scroll="{ y: '40vh' }" rowKey="id"
                :pagination="false">
                <a-table-column title="编号" data-index="id" align="center"></a-table-column>
                <a-table-column title="类型" align="center">
                  <template slot-scope="text">
                    <span v-if="text.category === 'sewage'">污水</span>
                    <span v-if="text.category === 'rain'">雨水</span>
                    <span v-if="text.type === 'well'">检查井</span>
                    <span v-else-if="text.type === 'pipe'">管线</span>
                    <span v-else>
                      <DataDictFinder dictType="deviceType" :dictValue="text.type" iconType="text" />
                    </span>
                  </template>
                </a-table-column>

                <a-table-column title="操作" width="90px" fixed="right" align="center">
                  <template slot-scope="text, row, index">
                    <a-space>
                      <a class="danger" @click.prevent="deleteText(index)">删除</a>
                    </a-space>
                  </template>
                </a-table-column>
              </a-table>
            </a-form-item>
          </a-col>

          <!-- <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="点位"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-button
                type="primary"
                size="small"
                @click="changeStatus('marker')"
                >添加</a-button
              >

              <a-table
                bordered
                size="small"
                :data-source="selectedMarkerList"
                :scroll="{ y: '40vh' }"
                :pagination="false"
              >
                <a-table-column title="名称">
                  <template slot-scope="text">
                    {{ text.name }}
                  </template>
                </a-table-column>

                <a-table-column
                  title="操作"
                  width="90px"
                  fixed="right"
                  align="center"
                >
                  <template slot-scope="text, row, index">
                    <a-space>
                      <a class="danger" @click.prevent="removeMarker(index)"
                        >删除</a
                      >
                    </a-space>
                  </template>
                </a-table-column>
              </a-table>
            </a-form-item>
          </a-col>-->
        </a-row>

        <div class="center">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button html-type="submit" type="primary">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { add } from "../api/task";
import { fetchList } from "@/api/setting/user";
import { mapGetters } from "vuex";

import request from "@/api/request";

function fetchRemarks() {
  return request({
    url: "/model-analysis/inspect/task/remark/list",
  });
}

export default {
  props: {
    selectedFacilityList: {
      type: Array,
      default: () => [],
    },
    selectedMarkerList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      user: {},
      userList: [],

      remarks: [{
        remark: '设备维护'
      },
      {
        remark: '管网巡检'
      }], // 任务说明中的备选
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("taskType");
    },
    statusList() {
      return this.findDataDict("taskStatus");
    },
  },

  mounted() {
    const str = window.localStorage.getItem("user");
    if (str) {
      this.user = JSON.parse(str);
    }

    fetchList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (res && Array.isArray(res.list)) {
        this.userList = Object.freeze(res.list);
      }
    });

    fetchRemarks().then((res) => {
      if (Array.isArray(res.list)) {
        console.log("fetched remarks", res.list)
        this.remarks = Object.freeze(res.list);
      }
    });
  },

  methods: {
    changeStatus(payload) {
      this.$emit("changeStatus", payload);
      if (payload === "marker") {
        this.$message.info("请点击地图拾取点位");
      } else {
        this.$message.info("请选择设备");
      }
    },
    deleteText(index) {
      this.$emit("deleteFacility", index);
    },
    removeMarker(index) {
      this.$emit("removeMarker", index);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...values,

            taskTime: values.taskTime.format("YYYY-MM-DD"),

            executorName: this.userList.find(
              (item) => item.id === values.executor
            ).name,

            initiator: this.user.id,
            initiatorName: this.user.name,

            facilityList: this.selectedFacilityList.map((item) => {
              return {
                ...item,
                deviceId: item.id,
                id: undefined,
              };
            }),

            remark: values.remark ? values.remark.join(" ") : "",
            // pointList: this.selectedMarkerList,
          };

          console.log(params);

          add({
            ...params,
          }).then(() => {
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  position: fixed;
  top: 12vh;
  right: 4vw;
  z-index: 99;
  width: 40vw;
  background-color: #000;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  height: 75vh;
  overflow: auto;

  &>.header {
    background-color: var(--theme-color);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 998;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.form {
  padding: 1em;

  .center {
    padding: 1em;
  }
}
</style>
