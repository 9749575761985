<template>
  <div class="tool-bar">
    <span class="select-all-box">
      <img
        class="select-all"
        src="@/assets/home/selectAll-active.png"
        v-if="checked"
        @click="onSelectAll(false)"
      />
      <img class="select-all" src="@/assets/home/selectAll.png" v-else @click="onSelectAll(true)" />
    </span>
    <div
      class="item"
      v-for="item in tools"
      :key="item.title"
      @click="onSelect(item)"
      :class="selected.indexOf(item.title) > -1 ? 'active' : ''"
    >
      <img :src="item.activeIcon" v-if="selected.indexOf(item.title) > -1" />
      <img :src="item.icon" v-else />
      <span>{{ item.title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tools: [
        {
          icon: "/icon/monitor.png",
          activeIcon: "/icon/monitor-active.png",
          title: "视频监控",
        },
        {
          icon: "/icon/flow.png",
          activeIcon: "/icon/flow-active.png",
          title: "流量计",
        },
        {
          icon: "/icon/liquid.png",
          activeIcon: "/icon/liquid-active.png",
          title: "液位计",
        },
        {
          icon: "/icon/rain.png",
          activeIcon: "/icon/rain-active.png",
          title: "雨量计",
        },
        {
          icon: "/icon/water-quality.png",
          activeIcon: "/icon/water-quality-active.png",
          title: "水质监测站",
        },

        {
          icon: "/icon/intercepting-well.png",
          activeIcon: "/icon/intercepting-well-active.png",
          title: "智慧截流井",
        },

        {
          icon: require("@/assets/assets/bottom-bar/sewage-pipe.png"),
          activeIcon: require("@/assets/assets/bottom-bar/sewage-pipe-active.png"),
          title: "污水管网",
        },
        {
          icon: require("@/assets/assets/bottom-bar/rain-pipe.png"),
          activeIcon: require("@/assets/assets/bottom-bar/rain-pipe-active.png"),
          title: "雨水管网",
        },
        {
          icon: "/icon/sewage-well.png",
          activeIcon: "/icon/sewage-well-active.png",
          title: "污水检查井",
        },
        {
          icon: "/icon/rain-well.png",
          activeIcon: "/icon/rain-well-active.png",
          title: "雨水检查井",
        },
      ],
    };
  },
  methods: {
    onSelect(item) {
      this.$emit("change", item);
    },
    onSelectAll(val) {
      this.$emit("onSelectAll", val);
    },
  },
};
</script>
<style lang="less" scoped>
.tool-bar {
  position: fixed;
  bottom: 2vh;
  left: 50vw;
  transform: translateX(-50%);
  background-color: #000;
  padding: 1vh 1vw;
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 3vh;

  .select-all-box {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 5vh;
      left: 5.5vh;
      background: linear-gradient(
        180deg,
        rgba(103, 146, 127, 0) 0%,
        #67927f 53%,
        rgba(103, 146, 127, 0) 100%
      );
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }

  .select-all {
    cursor: pointer;
    width: 4vh;
  }

  .item {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    cursor: pointer;

    img {
      width: 3vh;
      margin-bottom: 0.25em;
    }

    span {
      white-space: nowrap;
    }
  }

  .active {
    color: #fff;
  }
}
</style>
