<template>
  <div class="card">
    <div class="header">
      <div class="title">编辑任务</div>
    </div>

    <div class="form">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="任务单号">
              <a-input
                v-decorator="[
                  'number',
                  {
                    initialValue: text.number,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="任务名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: text.name,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="任务类型">
              <a-select
                v-decorator="[
                  'type',
                  {
                    initialValue: text.type,
                    rules: [
                      {
                        required: true,
                        message: '请选择',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="任务日期">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'taskTime',
                  {
                    initialValue: text.taskTime ? moment(text.taskTime) : null,
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="执行人">
              <a-select
                v-decorator="[
                  'executor',
                  {
                    initialValue: text.executor,
                    rules: [
                      {
                        required: true,
                        message: '请选择',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in userList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="任务说明"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-select
                mode="multiple"
                style="width: 100%"
                v-decorator="[
                  'remark',
                  {
                    initialValue: text.remark ? text.remark.split(' ') : [],
                    rules: [
                      {
                        required: true,
                        message: '请选择',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in remarks"
                  :key="item.remark"
                  :value="item.remark"
                  >{{ item.remark }}</a-select-option
                >
              </a-select>

              <!-- <a-auto-complete
                @focus="onFocus"
                @select="onSelect"
                :data-source="remarks.map((item) => item.remark)"
                v-decorator="[
                  'remark',
                  {
                       initialValue: text.remark,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              >
                <a-textarea :auto-size="{ minRows: 3 }" />
              </a-auto-complete>-->

              <!-- <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'remark',
                  {
                    initialValue: text.remark,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />-->
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="状态"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-radio-group
                v-decorator="[
                  'status',
                  {
                    initialValue: text.status,
                    rules: [
                      {
                        required: true,
                        message: '请选择',
                      },
                    ],
                  },
                ]"
              >
                <a-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="设备"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-button
                type="primary"
                size="small"
                @click="changeStatus('facility')"
                >添加</a-button
              >

              <a-table
                bordered
                size="small"
                :data-source="selectedFacilityList"
                :scroll="{ y: '40vh' }"
                rowKey="id"
                :pagination="false"
              >
                <a-table-column
                  title="编号"
                  data-index="id"
                  align="center"
                ></a-table-column>
                <a-table-column title="类型" align="center">
                  <template slot-scope="text">
                    <span v-if="text.category === 'sewage'">污水</span>
                    <span v-if="text.category === 'rain'">雨水</span>
                    <span v-if="text.type === 'well'">检查井</span>
                    <span v-else-if="text.type === 'pipe'">管线</span>
                    <span v-else>
                      <DataDictFinder
                        dictType="deviceType"
                        :dictValue="text.type"
                        iconType="text"
                      />
                    </span>
                  </template>
                </a-table-column>

                <a-table-column
                  title="操作"
                  width="90px"
                  fixed="right"
                  align="center"
                >
                  <template slot-scope="text, row, index">
                    <a-space>
                      <a class="danger" @click.prevent="deleteText(index)"
                        >删除</a
                      >
                    </a-space>
                  </template>
                </a-table-column>
              </a-table>
            </a-form-item>
          </a-col>

          <!-- <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="点位"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-button
                type="primary"
                size="small"
                @click="changeStatus('marker')"
                >添加</a-button
              >

              <a-table
                bordered
                size="small"
                :data-source="selectedMarkerList"
                :scroll="{ y: '40vh' }"
                :pagination="false"
              >
                <a-table-column title="名称">
                  <template slot-scope="text">
                    {{ text.name }}
                  </template>
                </a-table-column>

                <a-table-column
                  title="操作"
                  width="90px"
                  fixed="right"
                  align="center"
                >
                  <template slot-scope="text, row, index">
                    <a-space>
                      <a class="danger" @click.prevent="removeMarker(index)"
                        >删除</a
                      >
                    </a-space>
                  </template>
                </a-table-column>
              </a-table>
            </a-form-item>
          </a-col>-->
        </a-row>

        <div class="center">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button html-type="submit" type="primary">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { fetchDetail, edit } from "../api/task";
import { fetchList } from "@/api/setting/user";
import { mapGetters } from "vuex";

import moment from "moment";

import request from "@/api/request";

function fetchRemarks() {
  return request({
    url: "/model-analysis/inspect/task/remark/list",
  });
}

export default {
  props: {
    selectedFacilityList: {
      type: Array,
      default: () => [],
    },
    selectedMarkerList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      userList: [],
      text: {},

      remarks: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("taskType");
    },
    statusList() {
      return this.findDataDict("taskStatus");
    },
  },

  mounted() {
    fetchList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (res && Array.isArray(res.list)) {
        this.userList = Object.freeze(res.list);
      }
    });

    const { id, type } = this.$route.query;
    if (id && type) {
      fetchDetail({ id, type }).then((res) => {
        console.log("detial", res);
        if (res) {
          this.text = res;
          if (Array.isArray(res.facilityList)) {
            this.$emit(
              "changeFacility",
              res.facilityList.map((item) => {
                return {
                  ...item,
                  id: item.deviceId,
                  startLnglat: item.xfrom
                    ? [item.xfrom, item.yfrom]
                    : undefined,
                  endLnglat: item.xto ? [item.xto, item.yto] : undefined,
                };
              })
            );
          }

          if (Array.isArray(res.pointList)) {
            this.$emit(
              "changeMarker",
              res.pointList.map((item) => {
                return {
                  ...item,
                  x: parseFloat(item.x),
                  y: parseFloat(item.y),
                };
              })
            );
          }
        }
      });
    }

    fetchRemarks().then((res) => {
      if (Array.isArray(res.list)) {
        this.remarks = Object.freeze(res.list);
      }
    });
  },

  methods: {
    // onFocus() {
    //   this.remark = this.form.getFieldValue("remark") || "";
    // },
    // onSelect(value) {
    //   this.form.setFieldsValue({
    //     remark: this.remark + value,
    //   });
    // },
    changeStatus(payload) {
      this.$emit("changeStatus", payload);
      if (payload === "marker") {
        this.$message.info("请点击地图拾取点位");
      } else {
        this.$message.info("请选择设备");
      }
    },
    deleteText(index) {
      this.$emit("deleteFacility", index);
    },
    removeMarker(index) {
      this.$emit("removeMarker", index);
    },

    moment,

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...values,

            taskTime: values.taskTime.format("YYYY-MM-DD"),

            executorName: this.userList.find(
              (item) => item.id === values.executor
            ).name,

            facilityList: this.selectedFacilityList.map((item) => {
              return {
                ...item,
                deviceId: item.id,
                id: undefined,
              };
            }),

            remark: values.remark ? values.remark.join(" ") : "",
            // pointList: this.selectedMarkerList,

            // points: this.markers.map((item) => {
            //   return {
            //     id: item.id,
            //     name: item.title,
            //     x: item.lng,
            //     y: item.lat,
            //   };
            // }),
          };

          console.log(params);
          edit({
            id: this.text.id,
            ...params,
          }).then(() => {
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>


<style lang="less" scoped>
.card {
  position: fixed;
  top: 12vh;
  right: 4vw;
  z-index: 99;
  width: 40vw;
  background-color: #000;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  height: 75vh;
  overflow: auto;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 998;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.form {
  padding: 1em;

  .center {
    padding: 40px;
  }
}
</style>
