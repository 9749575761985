import { PointLayer } from '@antv/l7';
import { mapState } from 'vuex'

export default {

    computed: {
        ...mapState('home', ['facilityList'])
    },

    watch: {
        facilityList() {
            console.log('factility list changed', this.facilityList)
            if (this.facilityList.length > 0) {
                this.getMonitor();
                this.getLiquid();
                this.getFlow();
                this.getCuto();
                this.getRainfall();
                this.getWaterQuality();
            }
        }
    },

    methods: {
        getLiquid() {

            const data = this.facilityList.filter(item => item.type === 'liquidLevel');
            if (data.length === 0) return;


            if (!this.liquidLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.liquidLayer = new PointLayer({
                    zIndex: 1,

                }).source(data, {
                    parser: {
                        type: 'json',
                        x: 'x',
                        y: 'y'
                    }
                })
                    .shape('liquidLevel')
                    .size(zoom * 0.55)

                const that = this;
                this.liquidLayer.on('click', function (e) {


                    console.log('e', e)

                    if (that.addStatus === 'facility') {
                        const { id, x, y } = e.feature;

                        that.addFacility({
                            id,
                            type: 'liquidLevel',
                            x: parseFloat(x),
                            y: parseFloat(y)
                        })
                    }


                })
                this.scene?.addLayer(this.liquidLayer);


            } else {
                this.liquidLayer.show();
            }

        },
        removeLiquid() {
            this.liquidLayer?.hide();
        },

        getFlow() {

            const data = this.facilityList.filter(item => item.type === 'flowMeter');
            if (data.length === 0) return;

            if (!this.flowLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.flowLayer = new PointLayer({
                    zIndex: 1,

                })

                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('flowMeter')
                    .size(zoom * 0.55)

                const that = this;
                this.flowLayer.on('click', function (e) {

                    console.log('e', e)

                    if (that.addStatus === 'facility') {
                        const { id, x, y } = e.feature;

                        that.addFacility({
                            id,
                            type: 'flowMeter',
                            x: parseFloat(x),
                            y: parseFloat(y)
                        })

                    }


                })


                this.scene?.addLayer(this.flowLayer);

            } else {
                this.flowLayer.show();
            }

        },
        removeFlow() {
            this.flowLayer?.hide();
        },

        getCuto() {

            const data = this.facilityList.filter(item => item.type === 'cutoffWell');
            if (data.length === 0) return;

            if (!this.cutoLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.cutoLayer = new PointLayer({
                    zIndex: 1,

                })

                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('cutoffwell')
                    .size(zoom * 0.55)

                const that = this;
                this.cutoLayer.on('click', function (e) {

                    console.log('e', e)

                    if (that.addStatus === 'facility') {
                        const { id, x, y } = e.feature;

                        that.addFacility({
                            id,
                            type: 'cutoffWell',
                            x: parseFloat(x),
                            y: parseFloat(y)
                        })
                    }


                })


                this.scene?.addLayer(this.cutoLayer);

            } else {
                this.cutoLayer.show();
            }

        },
        removeCuto() {
            this.cutoLayer?.hide();
        },

        getMonitor() {

            const data = this.facilityList.filter(item => item.type === 'video');
            if (data.length === 0) return;


            if (!this.monitorLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.monitorLayer = new PointLayer({
                    zIndex: 1,

                })
                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('video')
                    .size(zoom * 0.55)

                const that = this;
                this.monitorLayer.on('click', function (e) {

                    console.log('e', e)

                    if (that.addStatus === 'facility') {
                        const { id, x, y } = e.feature;

                        that.addFacility({
                            id,
                            type: 'video',
                            x: parseFloat(x),
                            y: parseFloat(y)
                        })
                    }


                })


                this.scene?.addLayer(this.monitorLayer);

            } else {
                this.monitorLayer.show();
            }

        },
        removeMonitor() {
            this.monitorLayer?.hide();
        },

        getRainfall() {

            const data = this.facilityList.filter(item => item.type === 'rainFall');
            if (data.length === 0) return;

            if (!this.rainfallLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.rainfallLayer = new PointLayer({
                    zIndex: 100,

                })
                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('rainFall')
                    .size(zoom * 0.55)

                const that = this;
                this.rainfallLayer.on('click', function (e) {


                    console.log('e', e)

                    if (that.addStatus === 'facility') {

                        const { id, x, y } = e.feature;

                        that.addFacility({
                            id,
                            type: 'rainFall',
                            x: parseFloat(x),
                            y: parseFloat(y)
                        })

                    }

                })


                this.scene?.addLayer(this.rainfallLayer);

            } else {
                this.rainfallLayer.show();
            }

        },
        removeRainfall() {
            this.rainfallLayer?.hide();
        },

        getWaterQuality() {

            const data = this.facilityList.filter(item => item.type === 'waterquality');
            if (data.length === 0) return;

            if (!this.waterQualityLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.waterQualityLayer = new PointLayer({
                    zIndex: 100,

                })
                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('water-quality')
                    .size(zoom * 0.55)

                const that = this;
                this.waterQualityLayer.on('click', function (e) {

                    console.log('e', e)

                    if (that.addStatus === 'facility') {
                        const { id, x, y } = e.feature;

                        that.addFacility({
                            id,
                            type: 'waterquality',
                            x: parseFloat(x),
                            y: parseFloat(y)
                        })

                    }


                })


                this.scene?.addLayer(this.waterQualityLayer);

            } else {
                this.waterQualityLayer.show();
            }

        },
        removeWaterQuality() {
            this.waterQualityLayer?.hide();
        },

    }
}