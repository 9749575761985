import { fetchWell } from "../api/rain";
import { PointLayer } from '@antv/l7'

export default {

    methods: {
        getrainwell() {
            if (!this.rainWellLayer) {

                fetchWell({}).then((res) => {
                    if (res && Array.isArray(res.locs)) {
                        const list = Object.freeze(res.locs);

                        const zoom = this.map?.getZoom() || 3;

                        this.rainWellLayer = new PointLayer({
                            zIndex: 100,
                        })

                            .source(list.map(item => {
                                return {
                                    id: item[0],
                                    type: item[1],
                                    x: item[3],
                                    y: item[4],
                                }
                            }), {
                                parser: {
                                    type: 'json',
                                    x: 'x',
                                    y: 'y'
                                }
                            })
                            .shape('rainWell')
                            .size(zoom * 0.55)

                        this.rainWellLayer.on('click', this.rainWellClick)


                        this.scene?.addLayer(this.rainWellLayer);

                    }
                });



            } else {
                this.rainWellLayer.show();
            }

        },

        rainWellClick(e) {

            console.log('e', e)

            if (this.addStatus === 'facility') {
                const { id, x, y } = e.feature;

                this.addFacility({
                    id,
                    type: 'well',
                    category: 'rain',
                    x: parseFloat(x),
                    y: parseFloat(y)
                })
            }


        },

        removerainwell() {
            this.rainWellLayer?.hide();
        },

    }
}

