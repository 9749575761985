import { fetchRainPipe, } from "@/api/rain";
import { LineLayer } from '@antv/l7'

export default {

    methods: {
        getrainpipe() {
            if (!this.rainpipeLayer) {
                fetchRainPipe({}).then((res) => {
                    if (res && Array.isArray(res.locs)) {

                        let arr = []
                        for (let i = 0; i < res.locs.length; i++) {
                            const item = res.locs[i]

                            const startLnglat = [parseFloat(item[1]), parseFloat(item[2])]
                            const endLnglat = [parseFloat(item[3]), parseFloat(item[4])]

                            arr.push({
                                "type": "Feature",
                                "properties": {
                                    id: item[0],
                                    startLnglat,
                                    endLnglat
                                },
                                "geometry": {
                                    "type": "LineString",
                                    "coordinates": [
                                        startLnglat,
                                        endLnglat
                                    ]
                                }
                            })

                        }

                        const sources = {
                            "type": "FeatureCollection",
                            "features": arr
                        }

                        const rainpipeLayer = new LineLayer({
                            zIndex: 10,
                            depth: true,
                            name: 'rainPipeLayer'
                        }).source(sources).size(2)
                            .shape('line')
                            .color('#01C8E3')
                            .animate({
                                interval: 1, // 间隔
                                duration: 2, // 持续时间，延时
                                trailLength: 2 // 流线长度
                            });

                        rainpipeLayer.on('click', this.onrainpipeClick);

                        this.rainpipeLayer = rainpipeLayer;

                        this.scene?.addLayer(rainpipeLayer);

                    }
                });
            } else {
                this.rainpipeLayer?.show();
                this.activerainpipeLayer?.show();
            }
        },

        onrainpipeClick(e) {
            console.log('e', e)

            if (this.addStatus === 'facility') {
                const { id, startLnglat, endLnglat } = e.feature.properties;

                this.addFacility({
                    id,
                    type: 'pipe',
                    category: 'rain',
                    startLnglat,
                    endLnglat
                })
            }


        },

        removerainpipe() {
            this.rainpipeLayer?.hide();
        },

    }
}

