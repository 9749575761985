import { fetchSewagePipe, } from "@/api/sewage";
import { LineLayer } from '@antv/l7'

export default {

    methods: {
        getSewagePipe() {
            if (!this.sewagePipeLayer) {
                fetchSewagePipe({}).then((res) => {
                    if (res && Array.isArray(res.locs)) {

                        let arr = []
                        for (let i = 0; i < res.locs.length; i++) {
                            const item = res.locs[i]

                            const startLnglat = [parseFloat(item[1]), parseFloat(item[2])]
                            const endLnglat = [parseFloat(item[3]), parseFloat(item[4])]

                            arr.push({
                                "type": "Feature",
                                "properties": {
                                    id: item[0],
                                    startLnglat,
                                    endLnglat
                                },
                                "geometry": {
                                    "type": "LineString",
                                    "coordinates": [
                                        startLnglat,
                                        endLnglat,
                                    ]
                                }
                            })

                        }

                        const sources = {
                            "type": "FeatureCollection",
                            "features": arr
                        }

                        const sewagePipeLayer = new LineLayer({
                            zIndex: 10,
                            depth: true
                        }).source(sources).size(2)
                            .shape('line')
                            .color('#FFA500')
                            // .color('#1990FF')
                            .animate({
                                interval: 1, // 间隔
                                duration: 2, // 持续时间，延时
                                trailLength: 2 // 流线长度
                            });

                        sewagePipeLayer.on('click', this.onSewagePipeClick);
                        this.sewagePipeLayer = sewagePipeLayer;
                        this.scene?.addLayer(sewagePipeLayer);
                    }
                });
            } else {
                this.sewagePipeLayer?.show();
                this.activeSewagePipeLayer?.show();
                // if (this.sewagePipeDetail.id) {
                //     this.sewagePipeVisible = true;
                // }
            }
        },

        onSewagePipeClick(e) {

            console.log('e', e)

            if (this.addStatus === 'facility') {
                const { id, startLnglat, endLnglat } = e.feature.properties;

                this.addFacility({
                    id,
                    type: 'pipe',
                    category: 'sewage',
                    startLnglat,
                    endLnglat
                })
            }


        },

        removeSewagePipe() {
            this.sewagePipeLayer?.hide();
        },

    }
}

