<template>
  <div>
    <div id="map"></div>

    <ToolBar
      :selected="selected"
      :checked="checked"
      @change="onSelect"
      @onSelectAll="onSelectAll"
    />

    <Add
      v-if="page === 'task' && action === 'add'"
      :selectedFacilityList="selectedFacilityList"
      @deleteFacility="deleteFacility"
      :selectedMarkerList="selectedMarkerList"
      @changeStatus="changeStatus"
      @removeMarker="removeMarker"
    />

    <Edit
      v-if="page === 'task' && action === 'edit'"
      :selectedFacilityList="selectedFacilityList"
      @deleteFacility="deleteFacility"
      @changeFacility="changeFacility"
      :selectedMarkerList="selectedMarkerList"
      @changeStatus="changeStatus"
      @removeMarker="removeMarker"
      @changeMarker="changeMarker"
    />

    <TempAdd
      v-if="page === 'temp' && action === 'add'"
      :selectedFacilityList="selectedFacilityList"
      @deleteFacility="deleteFacility"
      :selectedMarkerList="selectedMarkerList"
      @changeStatus="changeStatus"
      @removeMarker="removeMarker"
    />

    <TempEdit
      v-if="page === 'temp' && action === 'edit'"
      :selectedFacilityList="selectedFacilityList"
      @deleteFacility="deleteFacility"
      @changeFacility="changeFacility"
      :selectedMarkerList="selectedMarkerList"
      @changeStatus="changeStatus"
      @removeMarker="removeMarker"
      @changeMarker="changeMarker"
    />

    <a-modal
      title="填写点位标题"
      :visible="addMarkerVisible"
      :footer="null"
      @cancel="cancelSaveMarker"
    >
      <a-input v-model="addMarkerTitle" style="margin-bottom: 1em" />
      <div class="right">
        <a-button type="primary" @click="saveMarker">保存</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import ToolBar from "../ToolBar.vue";

import sewagePipe from "./mixins/sewage-pipe";
import rainPipe from "./mixins/rain-pipe";
import sewageWell from "./mixins/sewage-well";
import rainWell from "./mixins/rain-well";
import facility from "./mixins/facility";

import { Scene, PointLayer, LineLayer } from "@antv/l7";
import { GaodeMapV2 } from "@antv/l7-maps";

import { debounce } from "lodash";

import { mapActions } from "vuex";

import Add from "../list/add";
import Edit from "../list/edit";
import TempAdd from "../temp/add.vue";
import TempEdit from "../temp/edit";

export default {
  components: {
    ToolBar,
    Add,
    Edit,
    TempAdd,
    TempEdit,
  },
  mixins: [sewagePipe, rainPipe, sewageWell, rainWell, facility],

  data() {
    return {
      allSelect: [
        "视频监控",
        "流量计",
        "液位计",
        "雨量计",
        "水质监测站",
        "智慧截流井",
        "污水管网",
        "雨水管网",
        "污水检查井",
        "雨水检查井",
      ],
      selected: [
        "视频监控",
        "流量计",
        "液位计",
        "雨量计",
        "水质监测站",
        "智慧截流井",
        "污水管网",
        "雨水管网",
        "污水检查井",
        "雨水检查井",
      ],
      checked: true,

      selectedFacilityList: [],
      selectedMarkerList: [],
      addStatus: "facility",
      addMarkerVisible: false,
      addMarkerTitle: "",
      addMarkerLngLat: {},

      page: "",
      action: "",
    };
  },

  watch: {
    selected() {
      if (this.selected.indexOf("视频监控") > -1) {
        this.getMonitor();
      } else {
        this.removeMonitor();
      }

      if (this.selected.indexOf("液位计") > -1) {
        this.getLiquid();
      } else {
        this.removeLiquid();
      }

      if (this.selected.indexOf("流量计") > -1) {
        this.getFlow();
      } else {
        this.removeFlow();
      }

      if (this.selected.indexOf("智慧截流井") > -1) {
        this.getCuto();
      } else {
        this.removeCuto();
      }

      if (this.selected.indexOf("雨量计") > -1) {
        this.getRainfall();
      } else {
        this.removeRainfall();
      }

      if (this.selected.indexOf("水质监测站") > -1) {
        this.getWaterQuality();
      } else {
        this.removeWaterQuality();
      }

      if (this.selected.indexOf("污水管网") > -1) {
        this.getSewagePipe();
      } else {
        this.removeSewagePipe();
      }

      if (this.selected.indexOf("雨水管网") > -1) {
        this.getrainpipe();
      } else {
        this.removerainpipe();
      }

      if (this.selected.indexOf("污水检查井") > -1) {
        this.getWell();
      } else {
        this.removeWell();
      }

      if (this.selected.indexOf("雨水检查井") > -1) {
        this.getrainwell();
      } else {
        this.removerainwell();
      }
    },
  },

  mounted() {
    this.debounceZoomChange = debounce(this.onZoomChange, 150);

    this.initMap();
    this.getSewagePipe();
    this.getrainpipe();
    this.getWell();
    this.getrainwell();

    this.getFacilityList();

    const { page, action } = this.$route.query;
    this.page = page;
    this.action = action;
  },

  beforeDestroy() {
    // this.map?.off("click", this.onMapClick);
    this.map?.off("zoomend", this.debounceZoomChange);
    this.map?.clearMap();
    this.scene?.destroy();
    this.map?.destroy();
  },

  methods: {
    ...mapActions("home", ["getFacilityList"]),
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }

      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976],
        zoom: 16,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
      });

      this.map = map;

      // AMap.plugin(["AMap.Scale", "AMap.HawkEye", "AMap.MapType"], function () {
      //   map.addControl(new AMap.Scale());
      //   map.addControl(new AMap.HawkEye({ isOpen: true }));
      //   map.addControl(new AMap.MapType());
      // });

      // map.on("click", this.onMapClick);

      map.on("zoomend", this.debounceZoomChange);

      this.scene = new Scene({
        id: "map",
        logoVisible: false,
        map: new GaodeMapV2({
          mapInstance: map,
        }),
      });

      this.scene.on("click", (e) => {
        if (this.addStatus === "marker") {
          console.log("scene click", e);

          const { lng, lat } = e.lnglat;
          this.addMarkerLngLat = {
            x: lng,
            y: lat,
          };
          this.addMarkerVisible = true;
        }
      });

      this.scene?.addImage("video", "/icon/monitor-active.png");
      this.scene?.addImage("liquidLevel", "/icon/liquid-active.png");
      this.scene?.addImage("flowMeter", "/icon/flow-active.png");
      this.scene?.addImage("cutoffwell", "/icon/intercepting-well-active.png");
      this.scene?.addImage("rainFall", "/icon/rain-active.png");
      this.scene?.addImage("water-quality", "/icon/water-quality-active.png");

      this.scene?.addImage("sewageWell", "/icon/sewage-well-active.png");
      this.scene?.addImage("rainWell", "/icon/rain-well-active.png");
    },

    // 点击单个图标
    onSelect(item) {
      const index = this.selected.indexOf(item.title);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(item.title);
      }

      if (this.selected.length !== this.allSelect.length) {
        this.checked = false;
      } else {
        this.checked = true;
      }
    },
    // 全选
    onSelectAll(val) {
      if (val) {
        this.selected = [...this.allSelect];
        this.checked = true;
        return;
      }

      this.checked = false;
      this.selected = [];
    },

    onZoomChange() {
      const zoom = this.map?.getZoom() || 3;

      this.liquidLayer?.size(zoom * 0.55);
      this.flowLayer?.size(zoom * 0.55);
      this.cutoLayer?.size(zoom * 0.55);
      this.monitorLayer?.size(zoom * 0.55);
      this.rainfallLayer?.size(zoom * 0.55);
      this.waterQualityLayer?.size(zoom * 0.55);
      this.alertLayer?.size(zoom * 0.55);
    },

    addFacility({ id, type, category, x, y, startLnglat, endLnglat }) {
      if (this.selectedFacilityList.find((item) => item.id === id)) {
        this.$message.error("请勿重复选择");
        return;
      }
      this.selectedFacilityList.push({
        id,
        type,
        category,
        x,
        y,
        startLnglat,
        endLnglat,
      });

      this.$message.success("已选择" + id);

      if (this.selectedFacilityLayer) {
        this.scene?.removeLayer(this.selectedFacilityLayer);
      }

      this.selectedFacilityLayer = new PointLayer({
        zIndex: 200,
      })
        .source(
          this.selectedFacilityList.filter((item) => item.x),
          {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          }
        )
        .shape("simple")
        .color("#f00")
        .size(10);

      this.scene?.addLayer(this.selectedFacilityLayer);

      if (this.selectedPipeLayer) {
        this.scene?.removeLayer(this.selectedPipeLayer);
      }

      const list = this.selectedFacilityList
        .filter((item) => item.startLnglat)
        .map((item) => {
          return {
            type: "Feature",
            properties: {
              id: item.id,
              startLnglat: item.startLnglat,
              endLnglat: item.endLnglat,
            },
            geometry: {
              type: "LineString",
              coordinates: [item.startLnglat, item.endLnglat],
            },
          };
        });

      console.log("list", list);

      const sources = {
        type: "FeatureCollection",
        features: list,
      };

      console.log("sources", sources);

      this.selectedPipeLayer = new LineLayer({
        zIndex: 200,
        depth: true,
      })
        .source(sources)
        .size(5)
        .shape("line")
        .color("#f00");

      this.scene?.addLayer(this.selectedPipeLayer);
    },

    changeFacility(payload) {
      this.selectedFacilityList = payload;

      if (this.selectedFacilityLayer) {
        this.scene?.removeLayer(this.selectedFacilityLayer);
      }

      this.selectedFacilityLayer = new PointLayer({
        zIndex: 200,
      })
        .source(
          this.selectedFacilityList.filter((item) => item.x),
          {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          }
        )
        .shape("simple")
        .color("#f00")
        .size(10);

      this.scene?.addLayer(this.selectedFacilityLayer);

      if (this.selectedPipeLayer) {
        this.scene?.removeLayer(this.selectedPipeLayer);
      }

      const list = this.selectedFacilityList
        .filter((item) => item.startLnglat)
        .map((item) => {
          return {
            type: "Feature",
            properties: {
              id: item.id,
              startLnglat: item.startLnglat,
              endLnglat: item.endLnglat,
            },
            geometry: {
              type: "LineString",
              coordinates: [item.startLnglat, item.endLnglat],
            },
          };
        });

      console.log("list", list);

      const sources = {
        type: "FeatureCollection",
        features: list,
      };

      console.log("sources", sources);

      this.selectedPipeLayer = new LineLayer({
        zIndex: 200,
        depth: true,
      })
        .source(sources)
        .size(5)
        .shape("line")
        .color("#f00");

      this.scene?.addLayer(this.selectedPipeLayer);
    },

    deleteFacility(index) {
      const item = this.selectedFacilityList[index];
      this.selectedFacilityList.splice(index, 1);

      this.$message.success("已删除" + item.id);

      if (this.selectedFacilityLayer) {
        this.scene?.removeLayer(this.selectedFacilityLayer);
      }

      if (this.selectedFacilityList.filter((item) => item.x).length > 0) {
        this.selectedFacilityLayer = new PointLayer({
          zIndex: 200,
        })
          .source(
            this.selectedFacilityList.filter((item) => item.x),
            {
              parser: {
                type: "json",
                x: "x",
                y: "y",
              },
            }
          )
          .shape("simple")
          .color("#f00")
          .size(10);

        this.scene?.addLayer(this.selectedFacilityLayer);

        console.log("this.selectedFacilityLayer", this.selectedFacilityLayer);
      }

      if (this.selectedPipeLayer) {
        this.scene?.removeLayer(this.selectedPipeLayer);
      }

      const sources = {
        type: "FeatureCollection",
        features: [
          this.selectedFacilityList
            .filter((item) => item.startLnglat)
            .map((item) => {
              return {
                type: "Feature",
                properties: {
                  id: item.id,
                  startLnglat: item.startLnglat,
                  endLnglat: item.endLnglat,
                },
                geometry: {
                  type: "LineString",
                  coordinates: [item.startLnglat, item.endLnglat],
                },
              };
            }),
        ],
      };

      this.selectedPipeLayer = new LineLayer({
        zIndex: 200,
        depth: true,
      })
        .source(sources)
        .size(5)
        .shape("line")
        .color("#f00");

      this.scene?.addLayer(this.selectedPipeLayer);
    },

    changeStatus(payload) {
      this.addStatus = payload;
    },
    saveMarker() {
      if (!this.addMarkerTitle) {
        this.$message.error("请填写标题");
        return;
      }
      this.selectedMarkerList.push({
        ...this.addMarkerLngLat,
        name: this.addMarkerTitle,
      });

      this.$message.success("已添加" + this.addMarkerTitle);

      this.addMarkerTitle = "";
      this.addMarkerVisible = false;
      this.addMarkerLngLat = {};

      if (this.selectedMarkerLayer) {
        this.scene?.removeLayer(this.selectedMarkerLayer);
      }

      this.selectedMarkerLayer = new PointLayer({
        zIndex: 100,
      })
        .source(
          this.selectedMarkerList.filter((item) => item.x),
          {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          }
        )
        .shape("simple")
        .color("#f00")
        .size(10);

      this.scene?.addLayer(this.selectedMarkerLayer);
    },

    changeMarker(payload) {
      this.selectedMarkerList = payload;

      if (this.selectedMarkerLayer) {
        this.scene?.removeLayer(this.selectedMarkerLayer);
      }

      this.selectedMarkerLayer = new PointLayer({
        zIndex: 100,
      })
        .source(
          this.selectedMarkerList.filter((item) => item.x),
          {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          }
        )
        .shape("simple")
        .color("#f00")
        .size(10);

      this.scene?.addLayer(this.selectedMarkerLayer);
    },

    cancelSaveMarker() {
      this.addMarkerTitle = "";
      this.addMarkerVisible = false;
      this.addMarkerLngLat = {};
    },

    removeMarker(index) {
      const item = this.selectedMarkerList[index];
      this.selectedMarkerList.splice(index, 1);
      this.$message.success("已删除" + item.name);

      if (this.selectedMarkerLayer) {
        this.scene?.removeLayer(this.selectedMarkerLayer);
      }

      if (this.selectedMarkerList.length > 0) {
        this.selectedMarkerLayer = new PointLayer({
          zIndex: 100,
        })
          .source(
            this.selectedMarkerList.filter((item) => item.x),
            {
              parser: {
                type: "json",
                x: "x",
                y: "y",
              },
            }
          )
          .shape("simple")
          .color("#f00")
          .size(10);

        this.scene?.addLayer(this.selectedMarkerLayer);
      }
    },
  },
};
</script>
<style lang="less" scoped>
#map {
  position: fixed;
  height: 105vh;
  width: 100vw;
  left: 0;
  top: 0;
}
</style>